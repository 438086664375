import React, {useState} from 'react';
import './styles/App.css';
import { Theme } from 'react-daisyui';
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Register from "./pages/Register";
import Login from "./pages/Login";
import {buildAndGetStorage, useTheme} from "./utils";
import config from "./settings/config.json";

function App() {
  const viewHeight = config.preferences.appearance.viewHeight;
  return (
       <Theme dataTheme={useTheme()} className="Theme">
         <div className="App" >
            <NavBar />
            <BrowserRouter>
              <Routes>
                <Route index element={<Register viewHeight={viewHeight} />} />
                <Route path="register" element={<Register viewHeight={viewHeight} />} />
                <Route path="login" element={<Login viewHeight={viewHeight} />} />
                <Route path="*" element={<Register viewHeight={viewHeight} />} />
              </Routes>
            </BrowserRouter>
            <Footer />
         </div>
      </Theme>
  );
}

export default App;
