import React from 'react';
import { Footer, Link } from 'react-daisyui';
import {buildAndGetStorage, useTheme, useLogo} from "../utils";
import config from "../settings/config.json";

const FooterComponent = ()=>{
  return (
    <Footer className="p-10">
      <div>
        <div className="logo m-0-auto">
          <a href="/" className="flex items-center sm:mb-0">
            <img src={useLogo()} className=" h-12" alt="FreshID Logo" />
          </a>
        </div>
        <p>
          Design by FID Dev Team's.
          <br />
          © 2021 freshid.shop
        </p>
      </div>

      <div>
        <Footer.Title>Services</Footer.Title>
        <Link hover={true}>Branding</Link>
        <Link hover={true}>Design</Link>
        <Link hover={true}>Marketing</Link>
        <Link hover={true}>Advertisement</Link>
      </div>
      <div>
        <Footer.Title>Company</Footer.Title>
        <Link hover={true}>About us</Link>
        <Link hover={true}>Contact</Link>
        <Link hover={true}>Jobs</Link>
        <Link hover={true}>Press kit</Link>
      </div>
      <div>
        <Footer.Title>Legal</Footer.Title>
        <Link hover={true}>Terms of use</Link>
        <Link hover={true}>Privacy policy</Link>
        <Link hover={true}>Cookie policy</Link>
      </div>
    </Footer>
  )
}

export default FooterComponent;