import {reloadStyle, BounceIn, FadeIn, FadeInRight} from "../components/Animation";
import { Form, Button, Link, Input } from 'react-daisyui';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import {buildAndGetStorage, handleVerificationSuccess, useTheme, pushRouter, myWindow} from "../utils";
import * as Skeleton from "../components/Skeleton";
import * as Assets from "../components/Assets";
import * as Connector from "../components/Connector";

const RegisterSkeleton = (params: any) =>{
	//${myWindow.innerHeight}
	//style={{height: myWindow.innerHeight}}
	Connector.registerGraph({firstName:"Susan",lastName:"Brand",password:"Tumotdentam@123",email:"pe4045@gmailwe.com",code:"132423"}); 
	return (<div style={{minHeight:params.viewHeight}} className="skeleton flex w-full p-4 justify-center items-center">
  <div className="hero-content flex-col lg:flex-row-reverse">
	    <div className="p-2 text-center ">
	      <h1 className="text-5xl font-bold">Register</h1>
	      <p className="py-6">Access and manage APIs for FreshID services.</p>
	      <Skeleton.BannerImage/>
	    </div>
	    <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
	    <Form>
	        <div className="form-control mx-5">
	          <Skeleton.Label><span className="label-text">Email</span></Skeleton.Label>
	          <Skeleton.Input/>
	        </div>
	        <div className="form-control mx-5">
	          <Skeleton.Label><span className="label-text">Password</span></Skeleton.Label>
	          <Skeleton.Input/>
	          <Skeleton.Label><span className="label-text">Confirm</span></Skeleton.Label>
	          <Skeleton.Input/>
	        </div>
        	<div className="form-control m-5">
        		<Skeleton.HCaptcha/>
        	</div>
	        <div className="form-control mx-5">
	          <Skeleton.Button />
	          <Skeleton.Link className="mb-1 label flow-root" title ="Login"/>
	        </div>
	      </Form>
	    </div>
  </div>
</div>)
}

const Register = (params: any) =>{
	pushRouter("/register");
	const render = Skeleton.RenderSkeleton({main:".skeleton--page",ske:".skeleton",timeOut:1000});
	return (<div id={render.id}>
		<RegisterSkeleton viewHeight={params.viewHeight} />
		<FadeIn className="skeleton--page">
		<div style={{minHeight:params.viewHeight}} className="flex w-full p-4 justify-center items-center bg-base-200">
		  <div className="hero-content flex-col lg:flex-row-reverse">
			    <div className="p-2 text-center ">
			      <h1 className="text-5xl font-bold">Register</h1>
			      <p className="py-6">Access and manage APIs for FreshID services.</p>
			      <FadeInRight>
			      	<img onLoad={()=>render.handleLoading()} src={useTheme() != "dark" ? Assets.registerLightBanner : Assets.registerDarkBanner} alt="FreshID Main Banner" className = "register-banner w-full h-full" />
			      </FadeInRight>
			    </div>
		    <BounceIn className="card flex-shrink-0 w-full max-w-sm">
			    <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
			    <Form>
			        <div className="form-control mx-5">
			          <label className="label"><span className="label-text">Email</span></label>
			          <Input type="text"placeholder="Email" className="input-bordered"/>
			        </div>
			        <div className="form-control mx-5">
			          <label className="label"><span className="label-text">Password</span></label>
			          <Input type="password"placeholder="Password" className="input-bordered"/>
			          <label className="label"><span className="label-text">Confirm</span></label>
			          <Input type="password"placeholder="Confirm" className="input-bordered"/>
			        </div>
		        	<div className="form-control m-5">
		        		<HCaptcha  theme={ useTheme() != "dark" ? "light" : "dark"}
				      sitekey="559dd5ea-0f57-4c58-9e14-fa6eafd9d63a"
				      onVerify={(token,ekey) => handleVerificationSuccess(token, ekey)}
				    />
		        	</div>
			        <div className="form-control mx-5">
			          <Button  color="primary">Submit</Button>
			          <Link className="mb-1 label flow-root" href="/login">Login</Link>
			        </div>
			      </Form>
			    </div>
		    </BounceIn>
		  </div>
		</div></FadeIn>
	</div>)
}

export default Register;