import {myFetch} from "../utils";
import config from "../settings/config.json";


const graphEnpoint = config.settings.connector.graph.endpoint;


//database
export const getGraphTables=()=>{
   myFetch(`${graphEnpoint}/api/v2/freshid/_table`, {
       method: "GET",
       credentials: "omit",
       referrer: "https://graph.freshid.shop",
       headers: {
         "key":"6a8b3a3ae44fdb102c5ca0b48e1dc0143a2bcb78f20b1020bb17c7998cb24cb9"
       },
   }).then((response : any)=>response.text()).then((responseText : any)=>{
      console.log(responseText);
   });
}

export const registerGraph=(params: any)=>{
   myFetch(`${graphEnpoint}/api/v2/user/register?login=true`, {
       method: "POST",
       body: JSON.stringify({
         "first_name": params.firstName,
         "last_name": params.lastName,
         "display_name":params.displayName? params.displayName : params.firstName+" "+params.lastName,
         "email": params.email,
         "new_password": params.password
       }),
       headers: {
         "content-type":"application/json",
       },
       credentials: "omit",
       referrer: "https://graph.freshid.shop"
   }).then((response : any)=>response.text()).then((responseText : any)=>{
      console.log(responseText);
   });
}

export const confirmRegisterGraph=(params: any)=>{
   myFetch(`${graphEnpoint}/api/v2/user/password?login=true`, {
       method: "POST",
       body: JSON.stringify({
         "code": params.code,
         "email": params.email,
         "username":params.email,
         "new_password": params.password,
         "verify_password": params.password,
       }),
       headers: {
         "content-type":"application/json",
       },
       credentials: "omit",
       referrer: "https://graph.freshid.shop"
   }).then((response : any)=>response.text()).then((responseText : any)=>{
      console.log(responseText);
   });
}