import React from 'react';
import styled, {keyframes, StyledComponent} from "styled-components";
import { fadeIn, bounceIn, fadeInRight } from 'react-animations';

const reloadStyle = (element : any) =>{
	element.outerHTML = element.outerHTML;
}

const FadeIn = styled.div`animation: 1.5s ${keyframes`${fadeIn}`}`;
const BounceIn = styled.div`animation: 0.5s ${keyframes`${bounceIn}`}`;
const FadeInRight = styled.div`animation: 0.5s ${keyframes`${fadeInRight}`}`;

export {reloadStyle, FadeIn, BounceIn, FadeInRight};

