import {useEffect, useId, useState} from "react";
import {reloadStyle, BounceIn, FadeIn, FadeInRight} from "../components/Animation";
import {buildAndGetStorage,handleVerificationSuccess,useTheme,pushRouter} from "../utils";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Form, Button, Link, Input } from 'react-daisyui';
import * as Skeleton from "../components/Skeleton";
import * as Assets from "../components/Assets";


const LoginSkeleton = (params: any)=>{
  return(<div style={{minHeight:params.viewHeight}} className="skeleton flex w-full p-4 justify-center items-center">
  <div className="hero-content flex-col lg:flex-row-reverse">
    <div className="p-2 text-center ">
      <h1 className="text-5xl font-bold">Login now!
      </h1>
      <p className="py-6">Access and manage APIs for FreshID services.
      </p>
      <Skeleton.BannerImage/>
    </div>
    <div className="card flex-shrink-0 w-full max-w-sm sc-bwzfXH hMzSFz">
      <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
      	<Form>
	          <div className="form-control mx-5">
	            <Skeleton.Label><span className="label-text">Email</span></Skeleton.Label>
	            <Skeleton.Input/>
	          </div>
	          <div className="form-control mx-5">
				<Skeleton.Label><span className="label-text">Password</span></Skeleton.Label>
	            <Skeleton.Input/>
	            <Skeleton.Label><a href="#" className="label-text-alt link link-hover">Forgot password?</a></Skeleton.Label>
	          </div>
	          <div className="form-control m-5">
	          	<Skeleton.HCaptcha/>
	          </div>
	          <div className="form-control mx-5 ">
	            <Skeleton.Button/>
	            <Skeleton.Link title="Register" className="mb-1 label flow-root"/>
	          </div>
      	</Form>

      </div>
    </div>
  </div>
</div>)
}

const Login = (params: any) =>{
	pushRouter("/login");
	const render = Skeleton.RenderSkeleton({main:".skeleton--page",ske:".skeleton",timeOut:1000});
	return (<div id={render.id}>
		<LoginSkeleton viewHeight ={params.viewHeight}/>
		<FadeIn className="skeleton--page">
		<div style={{minHeight:params.viewHeight}} className="flex w-full p-4 justify-center items-center bg-base-200 " >
  <div className="hero-content flex-col lg:flex-row-reverse">
  	
	    <div className="p-2 text-center ">
	      <h1 className="text-5xl font-bold">Login now!</h1>
	      <p className="py-6">Access and manage APIs for FreshID services.</p>
	      		<FadeInRight>
	      		<img  onLoad={()=>render.handleLoading()} src={useTheme() != "dark" ? Assets.loginLightBanner : Assets.loginDarkBanner} alt="FreshID Main Banner" className = "login-banner w-full h-full" />
	      		</FadeInRight>
	    </div>
    <BounceIn className="card flex-shrink-0 w-full max-w-sm">
	    <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
	      <Form >
	      <div/>
	        <div className="form-control mx-5 ">
	          <label className="label"><span className="label-text">Email</span></label>
	          <Input type="text"placeholder="Email" className="input-bordered"/>
	        </div>
	        <div className="form-control mx-5">
	        	<label className="label"><span className="label-text">Password</span></label>
	             <Input type="password"placeholder="Password" className="input-bordered"/>
	          <label className="label">
	            <a href="#" className="label-text-alt link link-hover">Forgot password?</a>
	          </label>
	        </div>
        	<div className="form-control m-5">
        		<HCaptcha  theme={ useTheme() != "dark" ? "light" : "dark"}
		      sitekey="559dd5ea-0f57-4c58-9e14-fa6eafd9d63a"
		      onVerify={(token,ekey) => handleVerificationSuccess(token, ekey)}
		    />
        	</div>
	        <div className="form-control mx-5 ">
	          <Button color="primary">Login</Button>
	          <Link className="mb-1 label flow-root" href="/register">Register</Link>
	        </div>
	      </Form>
	    </div>
    </BounceIn>
  </div>
</div>
</FadeIn>

	</div>);
}

export default Login;