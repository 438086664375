import config from "../settings/config.json";
import * as Assets from "../components/Assets";
import {reloadStyle} from "../components/Animation";


//variable
export const myWindow = window as any;
export const myDocument = document as any;
export const myFetch = fetch as any;
//variable--


//storage
export const getStorage =(item:string)=>{
   return eval(`
         var Storage = JSON.parse(localStorage.Storage);
         ${item.split("Storage").join("JSON.parse(localStorage.Storage)")};
   `);
}

export const loadStorage =()=>{
   return JSON.parse(localStorage.Storage);
}

export const saveStorage =(obj: any)=>{
   localStorage.Storage = JSON.stringify(obj);
}

const newStorage  =(item : string)=>{
   eval(`
      var Storage = JSON.parse(localStorage.Storage);
      ${item} = {};
      localStorage.Storage = JSON.stringify(Storage);
   `);
   return;
}

export function buildAndGetStoragePromise(item : string){
   try{
      if(!existsStorage(item)){
         var _start = "Storage";
         if(item.includes(_start)){
            var classes = (item.split(_start)[1]).split(".");
            classes.pop();
            localStorage.Storage = !localStorage.Storage ? "{}" : localStorage.Storage;
            if(classes.length >= 2){
               for (var i = 1; i < classes.length;i++){
                  const _class = classes[i];
                  _start +=  "." + _class;
                  if(!existsStorage(_start)){
                     newStorage(_start);
                  }
               }
               return Promise.resolve(getStorage(item));
            }
         }
         return Promise.resolve(getStorage(item));
      }
      return Promise.resolve(getStorage(item));
   }catch{
      alert('Reset all configuration!')
      localStorage.clear();
      myWindow.location.reload(true);
      return Promise.resolve(undefined);
   }
}

export function buildAndGetStorage(item : string){
   try{
      if(!existsStorage(item)){
         var _start = "Storage";
         if(item.includes(_start)){
            var classes = (item.split(_start)[1]).split(".");
            classes.pop();
            localStorage.Storage = !localStorage.Storage ? "{}" : localStorage.Storage;
            if(classes.length >= 2){
               for (var i = 1; i < classes.length;i++){
                  const _class = classes[i];
                  _start +=  "." + _class;
                  !existsStorage(_start) && newStorage(_start);
               }
            }
         }
      }
      //console.log("loadStorage =>",getStorage(item));
      return getStorage(item);
   }catch{
      alert('Reset all configuration!')
      localStorage.clear();
      myWindow.location.reload(true);
      return undefined;
   }
}

export const existsStorage  =(item : string)=>{
   try{
      return eval(`${item.split("Storage").join("JSON.parse(localStorage.Storage)")};`);
   }catch{
      return undefined;
   }
}
//storage--


//hcaptcha //secret  0x4c6FD08CA58CdC59FA9d0148d7e7F1807E6DEED7
export const handleVerificationSuccess = (token : string,ekey : string) =>{
   console.log("token =>",token);
   console.log("ekey =>",ekey);
}
//hcaptcha--


//theme-toggle
const bannerToggle =(selectTheme:string)=> {
  const router = getRouter();
  switch(router){
    case "/login": return selectTheme != "dark" ? [{selector: ".login-banner",src: Assets.loginLightBanner}] : [{selector: ".login-banner",src: Assets.loginDarkBanner}] ;
    case "/register": return selectTheme != "dark" ? [{selector: ".register-banner",src: Assets.registerLightBanner}] : [{selector: ".register-banner",src: Assets.registerDarkBanner}];
    default: return undefined;
  }
}

export const themeToggle   = () => {
  const theme = myDocument.querySelector(".Theme");
  const logos = myDocument.querySelectorAll(".logo");
  const [selectTheme, selectLogo] = theme.getAttribute("data-theme") == config.preferences.appearance.darkTheme.name ? [config.preferences.appearance.lightTheme.name,Assets.lightLogo] : [config.preferences.appearance.darkTheme.name,Assets.darkLogo, Assets.lightLogo];
  const selectBanners = bannerToggle(selectTheme);
  if(theme && logos.length > 0){
      selectBanners && selectBanners.length >0 && function(){
         for(const selectBanner of selectBanners){
             myDocument.querySelector(selectBanner.selector).src = selectBanner.src;
             reloadStyle(myDocument.querySelector(selectBanner.selector).parentElement);
           }
      }();
      theme.setAttribute("data-theme",selectTheme);
      buildAndGetStoragePromise("Storage.Preferences.Appearance").then(()=>{
        var Storage = loadStorage();
        Storage.Preferences.Appearance = selectTheme;
        saveStorage(Storage);
      });
      for(const logo of logos){
        logo.querySelector("img").setAttribute("src",selectLogo);
      }
      const captcha = myDocument.querySelector("iframe[data-hcaptcha-widget-id]");
      captcha && function(){
         captcha.src = captcha.src
                                 .split(`theme=${(captcha.src.split("theme=")[1]).split("&")[0]}`)
                                     .join(`theme=${selectTheme != "dark" ? "light" : "dark"}`)
                                       .split(`hcaptcha.html${captcha.src.match(/\?t=\d+/)?captcha.src.match(/\?t=\d+/)[0]:""}`)
                                         .join(`hcaptcha.html?t=${String(Date.now())}`);
      }();
      return;
  }
}
//theme-toggle--


//utils
export const updateURLParameter =(url : string,param : string,paramValue : string)=>{
   var href = new URL(url);
   href.searchParams.set(param, paramValue);
   return String(href);
}

export const isDarkTheme =()=>{
   return myWindow.matchMedia("(prefers-color-scheme: dark)").matches;
}

export const useTheme =()=>{
   const saveTheme = buildAndGetStorage("Storage.Preferences.Appearance");
   return saveTheme ? saveTheme : config.preferences.appearance.defaultTheme.enable ? config.preferences.appearance.defaultTheme.name : isDarkTheme() ? config.preferences.appearance.darkTheme.name : config.preferences.appearance.lightTheme.name;
}

export const useLogo =()=>{
   const currentTheme = useTheme();
   return currentTheme == config.preferences.appearance.darkTheme.name ? Assets.darkLogo : Assets.lightLogo;
}

export const isDeviceByUserAgent = ()=>{
   //mobile | desktop | table
   return /iPhone|Android/i.test(navigator.userAgent) ? "mobile" : /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(navigator.userAgent.toLowerCase()) ? "table" : "desktop";
}

export const getRouter =()=>{
   return new (URL as any)(myWindow.location.href).pathname;
}

export const pushRouter =(router: string)=>{
   getRouter() != router && myWindow.history.pushState({}, '', router);
}

//utils--
