import { HiBars3BottomLeft, HiOutlineQuestionMarkCircle, HiOutlineMoon } from "react-icons/hi2";
import {buildAndGetStorage, themeToggle} from "../utils";
import {Form, Input} from "react-daisyui";

const NavBar = () => {
  const dataTheme = buildAndGetStorage("Storage.Preferences.Appearance");
  return (
      <div className="navbar bg-base-100">
        <div className="navbar-start">
          <div className="dropdown ">
            <label tabIndex={0} className="btn btn-ghost btn-circle">
              <HiBars3BottomLeft className="h-8 w-8" stroke="currentColor"/>
            </label>
            <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
              <li><a href="/login">Login</a></li>
              <li><a href="/portfolio">Portfolio</a></li>
              <li><a href="/about">About</a></li>
            </ul>
          </div>
{/*          <FadeIn className="logo ml-2">
            <a href="/" className="flex items-center sm:mb-0">
              <img className="rounded-full" src={dataTheme == "dark" ? Assets.darkLogo : Assets.lightLogo} alt="FreshID Logo" />
            </a>
          </FadeIn>*/}
        </div>
{/*        <div className="navbar-center">
          <Form>
            <Input bordered type="text" placeholder="Search" />
          </Form>
        <FadeIn className="logo">

          <a href="/" className="flex items-center sm:mb-0">
            <img src={useLogo(48)} alt="FreshID Logo" />
            <span className="normal-case text-sm">.shop</span>
          </a>
        </FadeIn>

        </div>*/}
        <div className="navbar-end">
          <button className="btn btn-ghost btn-circle tooltip tooltip-bottom" data-tip="Support">
            <HiOutlineQuestionMarkCircle className="h-8 w-8" stroke="currentColor"/>
          </button>
           <button onClick={() => themeToggle()} className="ml-2 btn btn-ghost btn-circle tooltip tooltip-bottom" data-tip="Theme">
            <HiOutlineMoon className="h-8 w-8" stroke="currentColor"/>
          </button>
        </div>
      </div>
  );
}

export default NavBar;
